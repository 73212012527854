/* 
 */

.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #0f172a;
    text-align: center;
    padding-bottom: 100px;
    box-sizing: border-box;
    font-size: 4.5vw;
    color: rgb(202, 205, 208);
    font-family:'Courier New', Courier, monospace;
    letter-spacing: -1px;
    line-height: -10px;

    

    /* font-family: 'Inter-Medium.woff2'; */
}

.arrow-container {
    position: absolute;
    bottom: 70px;
    /* Adjust this value to position the arrows */
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .arrow-container:hover .arrow {
    border-color: teal;
    cursor: pointer;
  } */
  

.arrow {
    width: 1.5vw;
    height: 1.5vw;
    border-right: 2px solid teal;
    border-bottom: 2px solid teal;
    transform: rotate(45deg);
    margin: 0px;
    animation: bounce 2s infinite;
}

.arrow:nth-child(2) {
    animation-delay: 0.2s;
}

.arrow:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0) rotate(45deg);
    }

    40% {
        transform: translateY(-10px) rotate(45deg);
    }

    60% {
        transform: translateY(-5px) rotate(45deg);
    }
}