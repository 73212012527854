/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #bfbfbc;
  /* Ensure background color covers full viewport */
  font-family: 'Courier New', Courier, monospace;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  background-color: #BFBFBC;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* Prevent horizontal overflow */
}

.section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}