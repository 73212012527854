@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0d1626;
    padding: 10px 50px;
    height: 60px;
    font-family: 'Courier';
    color: rgb(202, 205, 208);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.navbar-logo {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 100%;
}

.navbar-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}

.navbar-menu a {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.navbar-menu a:hover svg {
    stroke: teal;
    cursor: pointer;
}

.navbar-menu button {
    margin-left: 20px;
    transition: color 0.3s, border-color 0.3s;
}

.navbar-menu button:hover {
    color: teal;
    border-color: teal;
    cursor: pointer;
}

.navbar-contact {
    display: flex;
    align-items: center;
}
